import { useMemo, HTMLAttributes } from 'react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useHomePageEventsQuery } from '@/apollo/operations';
import { PageSpinner } from '@/components/common-elements';
import {
  DesktopSearch as DefDesktopSearch,
  MobileSearch as DefMobileSearch,
} from '@/components/search';
import { styled } from '@mui/material/styles';
import { Text } from '../texts';
import { PublicEventPlayerShares as DefPublicEventPlayerShares } from './PublicEventPlayerShares';

export type HomePageEventsProps = HTMLAttributes<HTMLDivElement>;

const HomePageEvents = (props: HomePageEventsProps) => {
  const { t } = useTranslation();

  const { loading, error, data } = useHomePageEventsQuery({
    pollInterval: 30 * 1000,
  });

  const events = useMemo(() => data?.events ?? [], [data?.events]);

  return (
    <Wrapper {...props}>
      {(() => {
        if (loading && isEmpty(events)) {
          return <PageSpinner />;
        }

        if (error) {
          return (
            <Text align={'center'}>{t('HOME_PAGE_EVENTS__loadError')}</Text>
          );
        }

        return (
          <>
            <DesktopSearch />
            <Content>
              {isEmpty(events) ? (
                <Text align={'center'}>
                  {t('HOME_PAGE_EVENTS__emptyEvents')}
                </Text>
              ) : (
                events.map((event) => (
                  <PublicEventPlayerShares
                    key={event.id}
                    slider
                    event={event}
                  />
                ))
              )}
            </Content>
            <MobileSearch />
          </>
        );
      })()}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Content = styled('div')`
  flex-grow: 1;
  width: calc(100% + 40px);
  padding: 40px;
  margin: -40px;
  overflow: hidden;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 20px;
  }
`;

const DesktopSearch = styled(DefDesktopSearch)`
  margin-bottom: 48px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

const MobileSearch = styled(DefMobileSearch)`
  display: block;
  margin-top: auto;
  position: sticky;
  bottom: 24px;
  z-index: 1;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

const PublicEventPlayerShares = styled(DefPublicEventPlayerShares)`
  & + & {
    margin-top: 50px;
  }
`;

export { HomePageEvents };
