import { ComponentProps } from 'react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useSellShareEventsQuery } from '@/apollo/operations';
import { PageSpinner } from '@/components/common-elements';
import { SellShareEvent as DefSellShareEvent } from '@/components/events/SellShareEvent';
import { resetListStyles } from '@/styles';
import { styled } from '@mui/material/styles';
import { Text } from '../texts';

export type SellShareEventsProps = {
  playerId: string;
} & ComponentProps<typeof List>;

const SellShareEvents = ({ playerId, ...props }: SellShareEventsProps) => {
  const { t } = useTranslation();
  const { loading, error, data } = useSellShareEventsQuery({
    fetchPolicy: 'no-cache',
    variables: { playerId },
  });

  if (loading && isEmpty(data)) {
    return <PageSpinner />;
  }

  if (error) {
    return <Text align={'center'}>{t('SELL_SHARE_EVENTS__loadError')}</Text>;
  }

  if (isEmpty(data)) {
    return <Text align={'center'}>{t('SELL_SHARE_EVENTS__emptyItems')}</Text>;
  }

  return (
    <List {...props}>
      {data?.events.map((event) => (
        <ListItem key={event.id}>
          <SellShareEvent
            event={event}
            href={`/sell-share-tournaments/${event.slug}`}
          />
        </ListItem>
      ))}
    </List>
  );
};

const List = styled('ul')`
  ${resetListStyles};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px 24px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
  }
`;

const ListItem = styled('li')``;

const SellShareEvent = styled(DefSellShareEvent)`
  height: 100%;
`;

export { SellShareEvents };
