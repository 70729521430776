import { useMemo } from 'react';
import { min, max } from 'lodash-es';
import { SellShareEventsQuery } from '@/apollo/operations';
import { EventListItem, EventListItemProps } from '@/components/cards';
import dayjs from '@/utils/dayjs';
import { getPrice } from '@/utils/helpers';

export type SellShareEventProps = {
  event: Event;
} & Omit<EventListItemProps, 'event' | 'descriptions'> & {
    // For some reason href prop is not picked up from EventListItemProps
    href?: string;
  };

const SellShareEvent = ({ event, ...props }: SellShareEventProps) => {
  const descriptions = useMemo(() => getInfoItems(event), [event]);
  return <EventListItem event={event} descriptions={descriptions} {...props} />;
};

type Event = SellShareEventsQuery['events'][number];

const getInfoItems = (event: Event) => {
  const allBuyIns = event.tournaments.map(({ buyIn }) => buyIn);
  const buyInsRange = [min(allBuyIns), max(allBuyIns)] as const;
  const buyIns = [
    'SELL_SHARE_EVENT__buyIns',
    buyInsRange.map((val) => getPrice(val)).join(' - '),
  ] as const;

  const availableTournaments = [
    'SELL_SHARE_EVENT__availableTournaments',
    event.tournaments.length.toString(),
  ] as const;

  const datesStr = [event.dateFrom, event.dateTill]
    .map((val) => dayjs(val).format('D MMM'))
    .join(' - ');

  const dates = ['SELL_SHARE_EVENT__dates', datesStr] as const;

  const playerSharesAmount = event.tournaments
    .reduce((acc, { player_shares }) => {
      acc += player_shares.length;
      return acc;
    }, 0)
    .toString();

  const playerShares = [
    'SELL_SHARE_EVENT__playerShares',
    playerSharesAmount,
  ] as const;

  return [buyIns, availableTournaments, dates, playerShares] as const;
};

export { SellShareEvent };
