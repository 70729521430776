import { ComponentProps, useMemo } from 'react';
import { isNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useSnackbar } from 'notistack';
import {
  useUserFollowingsQuery,
  useFollowEventMutation,
  useUnFollowEventMutation,
  PublicEventPageDataFragment,
} from '@/apollo/operations';
import { Button, ButtonProps } from '@/components/buttons';
import { FacebookColorIcon } from '@/components/icons';
import { Text } from '@/components/texts';
import { useServerAuth } from '@/providers/ServerAuth';
import { externalLinkProps } from '@/utils/consts';
import dayjs from '@/utils/dayjs';
import { sendSentryError } from '@/utils/sentry';
import { styled, css } from '@mui/material/styles';

export type PublicEventHeaderProps = {
  event: Event;
} & ComponentProps<typeof Wrapper>;

const PublicEventHeader = ({ event, ...props }: PublicEventHeaderProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { authorized, userId } = useServerAuth();

  const { data: userFollowingsResponse } = useUserFollowingsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !authorized,
  });

  const userFollowings = useMemo(
    () => userFollowingsResponse?.followers ?? [],
    [userFollowingsResponse]
  );

  const dates = useMemo(() => {
    if (!(event.dateFrom && event.dateTill)) {
      return null;
    }
    const formatType = 'D MMMM YYYY';
    return `${dayjs(event.dateFrom).format(formatType)} - ${dayjs(
      event.dateTill
    ).format(formatType)}`;
  }, [event]);

  const showFollowError = () => {
    enqueueSnackbar(t('PUBLIC_EVENT_HEADER__followMutationError'), {
      variant: 'error',
    });
  };

  const showUnfollowError = () => {
    enqueueSnackbar(t('PUBLIC_EVENT_HEADER__unFollowMutationError'), {
      variant: 'error',
    });
  };

  const [followEvent, { loading: followingEvent }] = useFollowEventMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data.insertFollower?.id) {
        enqueueSnackbar(t('PUBLIC_EVENT_HEADER__followMutationSuccess'), {
          variant: 'success',
        });
      } else {
        showFollowError();
        sendSentryError(new Error('useFollowEventMutation result not ok'));
      }
    },
    onError: (e) => {
      showFollowError();
      sendSentryError(e);
    },
  });

  const [unFollowEvent, { loading: unFollowingEvent }] =
    useUnFollowEventMutation({
      fetchPolicy: 'no-cache',
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        const affectedRows = data.deleteFollowers?.affected_rows;
        if (isNumber(affectedRows) && affectedRows > 0) {
          enqueueSnackbar(t('PUBLIC_EVENT_HEADER__unFollowMutationSuccess'), {
            variant: 'success',
          });
        } else {
          showUnfollowError();
          sendSentryError(new Error('useUnFollowEventMutation result not ok'));
        }
      },
      onError: (e) => {
        showUnfollowError();
        sendSentryError(e);
      },
    });

  const headerButtonProps = useMemo<ButtonProps>(() => {
    const alreadyFollowing = userFollowings
      .map(({ eventId }) => eventId)
      .some((userFollowingEventId) => userFollowingEventId === event.id);

    if (!alreadyFollowing) {
      return {
        loading: followingEvent,
        children: t('PUBLIC_EVENT_HEADER__followButton'),
        onClick: () => {
          if (!userId) {
            enqueueSnackbar(t('PUBLIC_EVENT_HEADER__loginToFollowError'), {
              variant: 'warning',
            });
            return;
          }
          followEvent({
            variables: {
              eventId: event.id,
            },
          });
        },
      };
    } else {
      return {
        loading: unFollowingEvent,
        children: t('PUBLIC_EVENT_HEADER__unFollowButton'),
        onClick: () => {
          if (!userId) {
            enqueueSnackbar(t('PUBLIC_EVENT_HEADER__loginToFollowError'), {
              variant: 'warning',
            });
            return;
          }
          unFollowEvent({
            variables: {
              eventId: event.id,
            },
          });
        },
      };
    }
  }, [
    t,
    enqueueSnackbar,
    event,
    userId,
    userFollowings,
    followingEvent,
    unFollowingEvent,
    followEvent,
    unFollowEvent,
  ]);

  return (
    <Wrapper {...props}>
      <AvatarWrapper>
        {!!event.image && (
          <Avatar
            width={144}
            height={128}
            src={event.image}
            alt={'Event image'}
          />
        )}
      </AvatarWrapper>
      <Content>
        <ContentHeader>
          <ContentHeaderName
            component={'h1'}
            variant={'headline-large'}
            weight={'medium'}
          >
            {event.name}
          </ContentHeaderName>
          <ContentHeaderButton variant={'outlined'} {...headerButtonProps} />
          {!!event.location && (
            <ContentHeaderLocation variant={'title-large'} color={'primary'}>
              {event.location}
            </ContentHeaderLocation>
          )}
          <ContentHeaderFollowers color={'spunPearl'} variant={'body-large'}>
            {event.followersCount} {t('PUBLIC_EVENT_HEADER__followers')}
          </ContentHeaderFollowers>
        </ContentHeader>
        {[event.venue.facebook].some(Boolean) && (
          <ContentSocial>
            {!!event.venue.facebook && (
              <ContentSocialLink
                href={event.venue.facebook}
                {...externalLinkProps}
              >
                <FacebookColorIcon css={socialIconStyles} />
              </ContentSocialLink>
            )}
          </ContentSocial>
        )}
        {!!dates && (
          <ContentDetails>
            <ContentDetailsTitle variant={'title-medium'} weight={'semi-bold'}>
              {t('PUBLIC_EVENT_HEADER__dates')}:
            </ContentDetailsTitle>
            <ContentDetailsValue variant={'title-large'}>
              {dates}
            </ContentDetailsValue>
          </ContentDetails>
        )}
        {!!event.description && (
          <ContentDetails>
            <ContentDetailsTitle variant={'title-medium'} weight={'semi-bold'}>
              {t('PUBLIC_EVENT_HEADER__about')}:
            </ContentDetailsTitle>
            <ContentDetailsValue variant={'body-large'}>
              {event.description}
            </ContentDetailsValue>
          </ContentDetails>
        )}
      </Content>
    </Wrapper>
  );
};

type Event = PublicEventPageDataFragment;

const Wrapper = styled('header')`
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-gap: 16px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr;
  }
`;

// TODO: merge components with Player
const AvatarWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
`;
const Avatar = styled(Image)`
  width: 60%;
  height: auto;
  object-fit: cover;
`;
const Content = styled('div')``;
const ContentHeader = styled('header')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const ContentHeaderName = styled(Text)`
  flex: 1 1 auto;
`;
const ContentHeaderButton = styled(Button)`
  flex: 0 0 140px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    order: 1;
    margin-top: 8px;
  }
`;
const ContentHeaderLocation = styled(Text)`
  flex: 0 0 100%;
`;
const ContentHeaderFollowers = styled(Text)`
  flex: 0 0 100%;
`;
const ContentSocial = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
`;
const ContentSocialLink = styled(Link)``;
const socialIconStyles = css`
  display: block;
  width: 32px;
  height: 32px;
`;
const ContentDetails = styled('div')`
  margin-top: 16px;
`;
const ContentDetailsTitle = styled(Text)``;
const ContentDetailsValue = styled(Text)`
  margin-top: 4px;
  white-space: pre-wrap;
`;

export { PublicEventHeader };
