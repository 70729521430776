import { useMemo, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StatusesEnum,
  StatusGamesEnum,
  useEventDetailsQuery,
} from '@/apollo/operations';
import { PageSpinner } from '@/components/common-elements';
import { EventDetails } from '@/components/events/EventDetails';
import { Text as DefText } from '@/components/texts';
import { styled } from '@mui/material/styles';

export type SellShareEventTournamentsProps = {
  slug: string;
  playerId: string;
} & ComponentProps<typeof Wrapper>;

const SellShareEventTournaments = ({
  slug,
  playerId,
  ...props
}: SellShareEventTournamentsProps) => {
  const { t } = useTranslation();

  const { loading, error, data } = useEventDetailsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      slug,
      includePlayerShares: true,
      playerId,
      eventsWhere: {
        status: {
          _eq: StatusesEnum.Active,
        },
      },
      tournamentsWhere: {
        status: {
          _eq: StatusGamesEnum.Active,
        },
      },
    },
  });

  const event = useMemo(() => data?.events[0], [data]);

  return (
    <Wrapper {...props}>
      {(() => {
        if (loading && !event) {
          return <PageSpinner />;
        }

        if (error || !event) {
          return (
            <DefText align={'center'}>
              {t('SELL_SHARE_EVENT_TOURNAMENTS__loadError')}
            </DefText>
          );
        }

        return <EventDetails event={event} />;
      })()}
    </Wrapper>
  );
};

const Wrapper = styled('div')``;

export { SellShareEventTournaments };
