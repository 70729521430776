import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { PublicEventPageQuery } from '@/apollo/operations';
import { PlayerShareCard } from '@/components/cards';
import { PublicEventHeader } from '@/components/events/PublicEventHeader';
import { PlayerShareCardsList } from '@/components/styled/cards';
import { Text as DefText } from '@/components/texts';
import { useTournamentsPlayerShareCards } from '@/hooks';
import { resetListStyles } from '@/styles';
import dayjs from '@/utils/dayjs';
import { styled } from '@mui/material/styles';

export type PublicEventOverviewProps = {
  event: Event;
} & ComponentProps<typeof Wrapper>;

const PublicEventOverview = ({ event, ...props }: PublicEventOverviewProps) => {
  const { t } = useTranslation();
  const { getTournamentPlayerShares } = useTournamentsPlayerShareCards(
    event.location
  );
  return (
    <Wrapper {...props}>
      <PublicEventHeader event={event} />
      <TournamentsList>
        {event.tournaments.map((tournament) => {
          const shares = getTournamentPlayerShares(tournament);
          const limitExceed = shares.length > maxSharesAmount;
          return (
            <li key={tournament.id}>
              <TournamentTitle component={'h2'} variant={'display-small'}>
                {tournament.name}
                {' - '}
                {dayjs(tournament.date).format('D MMMM')}
              </TournamentTitle>
              <TournamentPlayerSharesList>
                {shares.slice(0, maxSharesAmount).map((share) => (
                  <PlayerShareCard key={share.id} data={share} />
                ))}
              </TournamentPlayerSharesList>
              {limitExceed && (
                <TournamentLimitText variant={'title-large'}>
                  <Link href={`/tournament/${tournament.slug}`}>
                    {t('PUBLIC_EVENT_PAGE__moreTournamentShares')}{' '}
                    {tournament.name}
                  </Link>
                </TournamentLimitText>
              )}
            </li>
          );
        })}
      </TournamentsList>
    </Wrapper>
  );
};

const maxSharesAmount = 8;

type Event = PublicEventPageQuery['events'][number];

const Wrapper = styled('div')``;

const TournamentsList = styled('ul')`
  ${resetListStyles};
`;

const TournamentTitle = styled(DefText)`
  margin-top: 50px;
  margin-bottom: 25px;
`;

const TournamentPlayerSharesList = styled(PlayerShareCardsList)``;

const TournamentLimitText = styled(DefText)`
  margin-top: 56px;
`;

export { PublicEventOverview };
